var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pageContainer"},[_c('el-card',{attrs:{"shadow":"never"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"供应商分类"}},[_c('el-select',{attrs:{"clearable":""},model:{value:(_vm.query.type),callback:function ($$v) {_vm.$set(_vm.query, "type", $$v)},expression:"query.type"}},_vm._l((_vm.SUPPLIER_TYPE),function(item){return _c('el-option',{key:item.key,attrs:{"value":item.key,"label":item.label}})}),1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.queryData}},[_vm._v("查询")])],1)],1)],1),_c('el-row',{attrs:{"type":"flex","justify":"start"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleClickAdd}},[_vm._v("新增供应商")])],1),_c('div',{staticClass:"tableWrap"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingList),expression:"loadingList"}],attrs:{"data":_vm.tableData,"border":"","row-key":"id"}},[_c('el-table-column',{attrs:{"fixed":"","prop":"name","label":"标题","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":{name: 'supplierDetail', params: { supplierId: row.id }}}},[_c('el-button',{attrs:{"type":"text"}},[_vm._v(_vm._s(row.name))])],1)]}}])}),_c('el-table-column',{attrs:{"label":"供应商类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("supplierType")(row.type))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"tag","label":"标签"}}),_c('el-table-column',{attrs:{"label":"供应商封面","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{staticClass:"displayImg",attrs:{"src":row.cover,"alt":""}})]}}])}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleDelete(row)}}},[_vm._v("删除")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleEdit(row)}}},[_vm._v("编辑")])]}}])})],1)],1),_c('el-pagination',{attrs:{"current-page":_vm.pageObj.currentPage,"total":_vm.pageObj.total,"page-sizes":_vm.pageObj.pageSizes,"page-size":_vm.pageObj.pageSize,"layout":_vm.pageObj.layout},on:{"update:currentPage":function($event){return _vm.$set(_vm.pageObj, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.pageObj, "currentPage", $event)},"current-change":_vm.handlerPageChange,"size-change":_vm.handlerSizeChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }